import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody} from '../../components/layouts'

import {
	Card,
	Loader,
	CheckBox,
	ProjectCard
} from '../../components/ui'

import {
	getImpact,
	selectProject,
	getUserProfile
} from '../../factory'

import Layout from '../../components/layout'

import theme from '../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showImpact: true
		}
		this.selectTab = this.selectTab.bind(this)
		this.onSelectProject = this.onSelectProject.bind(this)
	}

	componentDidMount() {
		this.props.getImpact(this.props.token)
	}

	selectTab(showImpact) {
		this.setState({showImpact})
	}

	onSelectProject(e) {
		const {selected_projects} = this.props
		const p_id = parseInt(e.target.value, 10)
		let selected_proj_ids = []
		if (selected_projects.length > 0 && typeof(selected_projects[0]) === 'object') {
			selected_proj_ids = selected_projects.map(item => item.project_id)
		} else {
			selected_proj_ids = selected_projects
		}

		const idx = selected_proj_ids.indexOf(p_id)
		if (idx > -1) {
			selected_proj_ids.splice(idx, 1)
		} else {
			selected_proj_ids.push(p_id)
		}

		this.props.selectProject(selected_proj_ids, this.props.token)
	}

	render() {
		const {user, organization, selected_projects, organization_projects, project_pending, pending} = this.props
		const { showImpact} = this.state
		let selected_proj_ids = []
		if (selected_projects.length > 0 && typeof(selected_projects[0]) === 'object') {
			selected_proj_ids = selected_projects.map(item => item.project_id)
		} else {
			selected_proj_ids = selected_projects
		}

		return (
			<Layout>
				<Container>
					<PhoneBody title={'Your Impact'}>
						<Flex column style={{background: '#F2F5F5', width: '100%', alignItems: 'center', paddingTop: 32, paddingBottom: 10, color: theme.colors.dustGray}}>
							<Paragraph>{user && user.first_name}, you’ve given</Paragraph>
							<Paragraph fontSize={62} color={'black'} lineHeight={'0.8em'}>${user && user.total_donation_sum}</Paragraph>
							<Paragraph textAlign={'center'}>Your contributions are helping <br/> {organization && organization.title}</Paragraph>
						</Flex>
						<Flex row style={{width: '100%', alignItems: 'center', color: theme.colors.dustGray, textAlign: 'center'}}>
							<div
								onClick={()=>this.selectTab(true)}
								style={{flex: 1,
									cursor: "pointer",
									borderRight: `2px solid #e1e0e1`,
									padding: "10px 5px",
									color: showImpact && 'black',
									borderBottom: `5px solid ${showImpact ? theme.colors.sunglow : '#e1e0e1'}`}}>
								<Paragraph>Selected Projects</Paragraph>
							</div>
							<div
								onClick={()=>this.selectTab(false)}
								style={{flex: 1,
									padding: "10px 5px",
									cursor: "pointer",
									color: !showImpact && 'black',
									borderBottom: `5px solid ${!showImpact ? theme.colors.sunglow : '#e1e0e1'}`}}>
								<Paragraph>Other Projects</Paragraph>
							</div>
						</Flex>
						{showImpact &&
						<Flex column>
							{selected_projects.map((proj) => <ProjectCard key={proj.id} project={proj}/>)}
						</Flex>
						}
						{!showImpact &&
						<Flex column style={{margin: '10px', padding: '0 40px 0 10px', width: '100%'}}>
							{organization_projects.map((item) =>
								(<Card style={{backgroundColor: '#F2F5F5', width: '100%', margin: '10px', padding: '10px 0'}}>
									<CheckBox style={{flex: 1, margin: '0.25rem'}} name={item.name} key={item.id} label={item.name} defaultSelected={selected_proj_ids.includes(item.id)} value={item.id} onChange={this.onSelectProject}/>
								</Card>)
							)}
							{project_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
								<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
									<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
										<Loader/>
										<Heading as="h1" fontSize={24}>Loading...</Heading>
									</div>
								</div>
							</div>
							}
						</Flex>
						}
						{pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Loading...</Heading>
								</div>
							</div>
						</div>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	organization: PropTypes.object,
	projects: PropTypes.array,
	token: PropTypes.string,
	getImpact: PropTypes.func,
	organization_projects: PropTypes.array,
	selected_projects: PropTypes.array,
	project_pending: PropTypes.bool,
	selectProject: PropTypes.func,
	pending: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		organization: state.userState.organization,
		selected_projects: state.userState.projects,
		organization_projects: state.donateNowState.projects,
		token: state.authState.accessToken,
		project_pending: state.userState.project_pending,
		pending: state.userState.pending
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getImpact: (token) => dispatch(getImpact(token)),
		selectProject: (proj_ids, token) => dispatch(selectProject(proj_ids, token)),
		getUserProfile: (token) => dispatch(getUserProfile(token)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
